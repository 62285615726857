
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Menu from "./components/Menu"
import NoPageFound from './components/NoPageFound';
import './style.css';
import HomePage from './components/HomePage';
import globalData from './data/global'
import NewItem from './components/Management/NewItem';
import { useDispatch,useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { selectedLanguage } from './components/Redux/Actions';
function App() {
  const setInitialLanguage = useDispatch();
  const  language = useSelector(state=>state.SELECTLANGUAGEREDUCER.selectedLanguage)

  // useEffect(() => {
  //   const data = async ()=>{
  //     const sss = await setInitialLanguage(selectedLanguage("ar"))
  //   }

  // },[])

  return (
    <div>
        <BrowserRouter>
          <Routes>
          <Route path="/" element={<HomePage globalData={globalData}/>}/>
          <Route path="/menu" element={<Menu globalData={globalData}/>}/>
          <Route path="/new" element={<NewItem/>}/>
          <Route path="/*" element={<NoPageFound/>}/>
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
