import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/fontawesome-free-solid'
const GoToTop = () => {
  const goToTop = ()=>{
    window.scrollTo(0, 0)
  }
  return (
    <div className="goToTop">
      <button onClick={goToTop}><FontAwesomeIcon icon={faArrowUp} /></button>
    </div>
  )
}

export default GoToTop
