import { createStore,applyMiddleware } from "redux";
import { combineReducers } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension"; composeWithDevTools()

import thunk from "redux-thunk";

import { backgroundMusicReducer } from "./BackgroundMusicReducer";

import { getCategoriesReducer } from "./categoriesReducers";

import { selectLanguageReducer } from "./selectLanguageReducer";

const reducers = combineReducers({
  GETMUSICREDUCER:backgroundMusicReducer,
  GETCATEGORIESREDUCER: getCategoriesReducer,
  SELECTLANGUAGEREDUCER:selectLanguageReducer
})
export const redSto = createStore(reducers, applyMiddleware(thunk))