import React, { useState } from 'react';
import { menuItems, menuItemsCategories } from '../data/data';
import { Link } from 'react-router-dom';
import MQ from './MQ';
import HaliIliya from '../components/HaliIliya';
import BackgroundMusic from './BackgroundMusic';
import SocialMedia from './SocialMedia';
import Offers from './Offers';
import GoToTop from './GoToTop';


import CarouselFadeExample from './carousel';
import useGetCategories from '../hooks/useGetCategories';

const Menu = ({ globalData }) => {

	const [category, setCategory] = useState(menuItemsCategories);
	const chooseCategory = (catID) => {
		if (catID === 'all') {
			setCategory(menuItemsCategories);
		} else {
			const setCat = menuItemsCategories.filter((item) => item.id === catID);
			setCategory(setCat);
		}
	};
	let checkPrice = (item, item2) => {
		if (item2 !== '') {
			return (
				<div>
					<div>🔥 {item2}</div>
					<div>
						<span>{item}</span>
					</div>
				</div>
			);
		} else {
			return item;
		}
	};
	let checkIfOffer = (item, item2) => {
		if (item2 !== '') {
			return 'OfferPriceDiscount';
		} else {
			return '';
		}
	};
	return (
		<div>
			
			<GoToTop/>
			<div className="menuContainer">
				<div className="menuTopButtons">
				
					<Link to="/">
						<div>🏠 {globalData.goHome}</div>
					</Link>
					<SocialMedia />
					<BackgroundMusic />
				</div>
				{/*ddd*/}



				<div className="menuActions">
						<button onClick={() => chooseCategory('all')}>الكل</button>
					{menuItemsCategories.map((i, index) => {
						return (
								<button key={index} onClick={() => chooseCategory(i.id)}>{i.cat}</button>
						);
					})}
				</div>
				<Offers />
				{category.map((item, Index) => {
					const itemsa = menuItems.filter((items) => items.cat === item.id);
					const NofI = itemsa.length;
					return (
						<div key={Index}>
							<div className="categorySec">
								<div className="catIndex">{Index + 1}#</div>
								<div>
									<div className="ItemCat">{item.cat}</div>
									<div className="NofI">
										({NofI} {globalData.item})
									</div>
								</div>
							</div>
							<div className="menusCarousel">
							<CarouselFadeExample images={item.image}/>
							</div>
							{itemsa.map((itemssssa, index) => {
								return (
									<div
										className={
											'catItems ' +
											checkIfOffer(itemssssa.price, itemssssa.disc)
										}
										key={index}
									>
										<div className="itemsNamesAndPrices">
											<div>{itemssssa.item}</div>
											<div>{checkPrice(itemssssa.price, itemssssa.disc)}</div>
										</div>
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
			<MQ />
			<HaliIliya />
		</div>
	);
};

export default Menu;
