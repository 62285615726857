import React from 'react'
import globalData from '../data/global'
import {menuItems,menuItemsCategories} from '../data/data'
const Offers = () => {

  return (
    <div>
      {
       menuItems.filter((items)=>items.disc !== "").map((items,index) =>(

        (index === 0) ? (
          
          <div className="categorySecOffers">
          <div className="so"><img src="images/siteImages/redBanner.png" alt="redBanner"/></div>
          <div>
            <div className="ItemCat">{globalData.todaysOffer}</div>
          </div>
        </div>
        ) : null
       ))
       }
      {
        menuItems.filter((item)=>item.disc !== "").map((itemsa,indexa)=>{
          
          return (
    <div className={'catItemsOffers'} key={indexa}>
      <div className="itemsNamesAndPricesOffers">
      <div>
        <span>#{indexa+1}</span> {itemsa.item}{" / "}{
        menuItemsCategories.filter((itemo)=>itemo.id===itemsa.cat).map((itemsao)=>{
          return itemsao.cat
        })
        }
        </div>
          <div>🔥 {itemsa.disc}</div>
        </div>
    </div>
          )
        })
      }


    </div>
  )
}

export default Offers
