export const getCategoriesReducer = (state= {categoriesia:[]},action)=>{
  switch(action.type){
    case "GET_ALL_CATEGORIES_TYPE":
      return {categoriesia: action.categoriesAc}
    default:
      return state
  }
}
export const addCategoriesReducer = (state= {categoriesia:""},action)=>{
  switch(action.type){
    case "ADD_NEW_CATEGORIES_TYPE":
      return {resoia : action.resAC, categoriesia:action.categoriesAc}
    default:
      return state
  }
}