import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay,faStop } from '@fortawesome/fontawesome-free-solid';

export const backgroundMusicReducer = (state={counter:1,menuStartStopButton:<FontAwesomeIcon icon={faPlay}/>,homeStartStopButton:"تشغيل الموسيقي"},action)=>{
  if(action.type==="PLAYANDPAUSE"){
    var newMenuStartStopButton = state.menuStartStopButton
    // var newHomeStartStopButton = state.homeStartStopButton
    if(state.counter % 2 === 1){
      newMenuStartStopButton = <FontAwesomeIcon icon={faStop} />;
    }else{
      newMenuStartStopButton = <FontAwesomeIcon icon={faPlay} />;
    }
    return {counter:state.counter + 1,menuStartStopButton:newMenuStartStopButton}
  }
  return state;
}