import React from 'react'
const HaliIliya = () => {
  return (
    <div>

      <div className="developerLogo">
        <a href="https://haliiliya.com/" target="_blank" rel="noreferrer"><img src="images/siteImages/haliIliyaLogo.png" alt="haliIliyaLogo"/></a>
      </div>
    </div>
  )
}

export default HaliIliya
