import React from 'react'
import globalData from '../data/global'
import HaliIliya from '../components/HaliIliya'
const MQ = () => {
  return (
      <div className="viewScreenMessage">
        <br></br> {globalData.mq} <br></br><br></br>
        <HaliIliya/>
      </div>
      
  )
}

export default MQ
