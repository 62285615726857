const globalData = {
  welcome: "اهلاً وسهلاً بكم في فرانك كافيه",
  firstHPAD:"",
  secondHPAD:"",
  menuButtonName:"افتح المنيو",
  todaysOffer:"عرض اليوم، لو تلحك لو ماتلحك! ",
  
  goHome:"الرئيسية",
  mq: "هذا الموقع لا يعمل في هذا الجهاز",
  item: "عناصر",
  oldPrice:"السعر القديم",
  pauseMusic:"🎹 ايقاف الموسيقي",
  playMusic:"🎹 تشغيل الموسيقي",
  followUsOnInsta: "تابعنا على الأنستجرام"
}

export default globalData