const menuItemsCategories = [
  {id:1,cat:"المشروبات الساخنة",image:["images/1-1.jpg","images/1-2.jpg","images/1-3.jpg"]},
  {id:2,cat:"قهوة مثلجة",image:["images/2-1.jpg","images/2-2.jpg"]},
  {id:3,cat:"ميلك شيك ",image:["images/3-1.jpg","images/3-2.jpg"]},
  {id:4,cat:"عصير طبيعي",image:["images/4-1.jpg","images/4-2.jpg"]},
  {id:5,cat:"المشروبات الغازية",image:["images/9-1.jpg"]},
  {id:6,cat:"موهيتو",image:["images/5-1.jpg"]},
  {id:7,cat:"سموثي",image:["images/6-1.jpg","images/6-2.jpg"]},
  {id:8,cat:"فرابي",image:["images/7-1.jpg","images/7-2.jpg"]},
  {id:9,cat:"الحلويات",image:["images/8-1.jpg","images/8-2.jpg","images/8-3.jpg"]},
  {id:10,cat:"سندويشات والوجبات",image:["images/10-1.jpg","images/12-1.jpg","images/10-2.jpg","images/12-2.jpg"]},
  {id:11,cat:"بيتزا",image:["images/11-1.jpg","images/11-2.jpg","images/11-3.jpg"]},
  {id:12,cat:"مقبلات وسلطات",image:["images/13-1.jpg","images/13-2.jpg","images/13-3.jpg"]},
  {id:13,cat:"اراكيل",image:["images/14-1.jpg","images/14-2.jpg","images/14-3.jpg"]},
]

const menuItems = [

  // Cat: 1
  {id:1,cat:1,image:"images/items/img.jpg",item:"شوكولاته ايطالي",price:"3000 IQD",disc:""},
  {id:3,cat:1,image:"images/items/img.jpg",item:"قهوة تركية",price:"2000 IQD",disc:""},
  {id:3,cat:1,image:"images/items/img.jpg",item:"قهوة حب خضرة",price:"2000 IQD",disc:""},
  {id:4,cat:1,image:"images/items/img.jpg",item:"قهوة حب خضرة دبل",price:"3000 IQD",disc:""},
  {id:5,cat:1,image:"images/items/img.jpg",item:"اسبريسو",price:"2000 IQD",disc:""},
  {id:6,cat:1,image:"images/items/img.jpg",item:"اسبريسو دبل",price:"3000 IQD",disc:""},
  {id:7,cat:1,image:"images/items/img.jpg",item:"شاي",price:"1000 IQD",disc:""},
  {id:8,cat:1,image:"images/items/img.jpg",item:"حليب بالعسل",price:"3000 IQD",disc:""},
  {id:9,cat:1,image:"images/items/img.jpg",item:"حليب",price:"2500 IQD",disc:""},
  {id:10,cat:1,image:"images/items/img.jpg",item:"امريكانو",price:"2000 IQD",disc:""},
  {id:11,cat:1,image:"images/items/img.jpg",item:"نسكافيه",price:"3000 IQD",disc:""},
  {id:12,cat:1,image:"images/items/img.jpg",item:"كابتشينو",price:"3000 IQD",disc:""},
  {id:13,cat:1,image:"images/items/img.jpg",item:"هوت شوكلت",price:"3000 IQD",disc:""},
  {id:14,cat:1,image:"images/items/img.jpg",item:"سحلب",price:"3000 IQD",disc:""},
  {id:15,cat:1,image:"images/items/img.jpg",item:"كافي لاتيه",price:"3000 IQD",disc:""},
  {id:16,cat:1,image:"images/items/img.jpg",item:"كافي موكا",price:"3000 IQD",disc:""},
  {id:17,cat:1,image:"images/items/img.jpg",item:"نومي بصرة",price:"2000 IQD",disc:""},
  {id:18,cat:1,image:"images/items/img.jpg",item:"كراميل مكياتو",price:"3000 IQD",disc:""},
  {id:19,cat:1,image:"images/items/img.jpg",item:"سبانش لاتيه",price:"3000 IQD",disc:""},

  // Cat: 2
  {id:201,cat:2,image:"images/items/img.jpg",item:"ايس كافيه",price:"4000 IQD",disc:""},
  {id:202,cat:2,image:"images/items/img.jpg",item:"ايس كابتشينو",price:"4000 IQD",disc:""},
  {id:203,cat:2,image:"images/items/img.jpg",item:"ايس لاتيه",price:"4000 IQD",disc:""},
  {id:204,cat:2,image:"images/items/img.jpg",item:"ايس كراميل",price:"4000 IQD",disc:""},
  {id:205,cat:2,image:"images/items/img.jpg",item:"ايس شوكولاته",price:"4000 IQD",disc:""},
  {id:206,cat:2,image:"images/items/img.jpg",item:"ايس فانيلا",price:"4000 IQD",disc:""},
  {id:207,cat:2,image:"images/items/img.jpg",item:"ايس موكا",price:"4000 IQD",disc:""},
  {id:208,cat:2,image:"images/items/img.jpg",item:"ايس تي",price:"4000 IQD",disc:""},
  {id:209,cat:2,image:"images/items/img.jpg",item:"ايس امريكانو",price:"3000 IQD",disc:""},

  // Cat: 3
  {id:401,cat:3,image:"images/items/img.jpg",item:"ميلك شيك فستق",price:"4000 IQD",disc:""},
  {id:402,cat:3,image:"images/items/img.jpg",item:"ميلك شيك باونتي",price:"4000 IQD",disc:""},
  {id:403,cat:3,image:"images/items/img.jpg",item:"ميلك شيك سنيكرز",price:"4000 IQD",disc:""},
  {id:404,cat:3,image:"images/items/img.jpg",item:"ميلك شيك تويكس",price:"4000 IQD",disc:""},
  {id:405,cat:3,image:"images/items/img.jpg",item:"ميلك شيك اوريو",price:"4000 IQD",disc:""},
  {id:406,cat:3,image:"images/items/img.jpg",item:"ميلك شيك لوتس",price:"4000 IQD",disc:""},
  {id:407,cat:3,image:"images/items/img.jpg",item:"ميلك شيك نوتيلا",price:"4000 IQD",disc:""},
  {id:408,cat:3,image:"images/items/img.jpg",item:"ميلك شيك كيت كات",price:"4000 IQD",disc:""},
  {id:409,cat:3,image:"images/items/img.jpg",item:"ميلك شيك شوكولاتا",price:"4000 IQD",disc:""},
  {id:410,cat:3,image:"images/items/img.jpg",item:"ميلك شيك فراولة",price:"4000 IQD",disc:""},

  // Cat: 4
  {id:601,cat:4,image:"images/items/img.jpg",item:"عصير افوكادو",price:"5000 IQD",disc:""},
  {id:602,cat:4,image:"images/items/img.jpg",item:"عصير طبيعي مانكو",price:"5000 IQD",disc:""},
  {id:603,cat:4,image:"images/items/img.jpg",item:"عصير برتقال",price:"3000 IQD",disc:""},
  {id:604,cat:4,image:"images/items/img.jpg",item:"عصير ليمون",price:"3000 IQD",disc:""},
  {id:605,cat:4,image:"images/items/img.jpg",item:"عصير اناناس",price:"4000 IQD",disc:""},
  {id:606,cat:4,image:"images/items/img.jpg",item:"عصير رمان",price:"4000 IQD",disc:""},
  {id:607,cat:4,image:"images/items/img.jpg",item:"عصير سندي",price:"3000 IQD",disc:""},
  {id:608,cat:4,image:"images/items/img.jpg",item:"عصير تفاح اخضر",price:"3000 IQD",disc:""},
  {id:610,cat:4,image:"images/items/img.jpg",item:"عصير فراولة",price:"4000 IQD",disc:""},
  {id:611,cat:4,image:"images/items/img.jpg",item:"عصير موز مع حليب",price:"3000 IQD",disc:""},
  {id:612,cat:4,image:"images/items/img.jpg",item:"عصير كوكتيل فواكه",price:"4000 IQD",disc:""},
  {id:613,cat:4,image:"images/items/img.jpg",item:"عصير بولو",price:"4000 IQD",disc:""},

  // Cat: 5
  {id:801,cat:5,image:"images/items/img.jpg",item:"مكسيكي",price:"3000 IQD",disc:""},
  {id:802,cat:5,image:"images/items/img.jpg",item:"ريدبول",price:"3000 IQD",disc:""},
  {id:803,cat:5,image:"images/items/img.jpg",item:"تايجر",price:"2000 IQD",disc:""},
  {id:804,cat:5,image:"images/items/img.jpg",item:"ميكس ريتبول",price:"4000 IQD",disc:""},
  {id:805,cat:5,image:"images/items/img.jpg",item:"ريدبول مكسيكي",price:"4000 IQD",disc:""},
  {id:806,cat:5,image:"images/items/img.jpg",item:"كوكاكولا",price:"1000 IQD",disc:""},
  {id:807,cat:5,image:"images/items/img.jpg",item:"كوكاكولا تركي دايت",price:"2000 IQD",disc:""},
  {id:808,cat:5,image:"images/items/img.jpg",item:"سبرايت",price:"1000 IQD",disc:""},
  {id:809,cat:5,image:"images/items/img.jpg",item:"فانتا",price:"1000 IQD",disc:""},
  {id:810,cat:5,image:"images/items/img.jpg",item:"صودا",price:"1000 IQD",disc:""},
  {id:811,cat:5,image:"images/items/img.jpg",item:"ماء",price:"500 IQD",disc:""},

  // Cat: 6
  {id:1001,cat:6,image:"images/items/img.jpg",item:"موهيتو فراولة",price:"3500 IQD",disc:""},
  {id:1002,cat:6,image:"images/items/img.jpg",item:"موهيتو مانجو",price:"3500 IQD",disc:""},
  {id:1003,cat:6,image:"images/items/img.jpg",item:"موهيتو كرز",price:"3500 IQD",disc:""},
  {id:1004,cat:6,image:"images/items/img.jpg",item:"موهيتو ليمون نعناع",price:"3500 IQD",disc:""},
  {id:1005,cat:6,image:"images/items/img.jpg",item:"موهيتو بلو",price:"3500 IQD",disc:""},
  {id:1006,cat:6,image:"images/items/img.jpg",item:"موهيتو توت",price:"3500 IQD",disc:""},
  {id:1007,cat:6,image:"images/items/img.jpg",item:"موهيتو اناناس",price:"3500 IQD",disc:""},
  {id:1008,cat:6,image:"images/items/img.jpg",item:"موهيتو بلو بيري",price:"3500 IQD",disc:""},
  {id:1009,cat:6,image:"images/items/img.jpg",item:"موهيتو كوكتيل فرانك",price:"3500 IQD",disc:""},


  // Cat: 7
  {id:1201,cat:7,image:"images/items/img.jpg",item:"سموثي فرانك",price:"5000 IQD",disc:""},
  {id:1202,cat:7,image:"images/items/img.jpg",item:"سموثي فراولة",price:"4000 IQD",disc:""},
  {id:1203,cat:7,image:"images/items/img.jpg",item:"سموثي بلوبيري",price:"4000 IQD",disc:""},
  {id:1204,cat:7,image:"images/items/img.jpg",item:"سموثي موز",price:"4000 IQD",disc:""},
  {id:1205,cat:7,image:"images/items/img.jpg",item:"سموثي شوكولاته",price:"4000 IQD",disc:""},
  {id:1206,cat:7,image:"images/items/img.jpg",item:"سموثي اناناس",price:"4000 IQD",disc:""},
  {id:1207,cat:7,image:"images/items/img.jpg",item:"سموثي مانكو",price:"4000 IQD",disc:""},
  {id:1208,cat:7,image:"images/items/img.jpg",item:"سموثي كرز",price:"4000 IQD",disc:""},
  {id:1209,cat:7,image:"images/items/img.jpg",item:"سموثي توت",price:"4000 IQD",disc:""},
  {id:1210,cat:7,image:"images/items/img.jpg",item:"سموثي باشون فروت",price:"4000 IQD",disc:""},
  {id:1211,cat:7,image:"images/items/img.jpg",item:"سموثي كوكتيل ريدبول",price:"5000 IQD",disc:""},


  // Cat: 8
  {id:1401,cat:8,image:"images/items/img.jpg",item:"فرابي شوكولاتث",price:"4000 IQD",disc:""},
  {id:1402,cat:8,image:"images/items/img.jpg",item:"فرابي قهوة",price:"4000 IQD",disc:""},
  {id:1403,cat:8,image:"images/items/img.jpg",item:"فرابي موكا",price:"4000 IQD",disc:""},
  {id:1404,cat:8,image:"images/items/img.jpg",item:"فرابي فانيلا",price:"4000 IQD",disc:""},
  {id:1405,cat:8,image:"images/items/img.jpg",item:"فرابي كراميك",price:"4000 IQD",disc:""},

  // Cat: 9
  {id:1601,cat:9,image:"images/items/img.jpg",item:"ايس كريم شوكولاته",price:"3000 IQD",disc:""},
  {id:1602,cat:9,image:"images/items/img.jpg",item:"ايس كريم فواكه",price:"4000 IQD",disc:""},
  {id:1603,cat:9,image:"images/items/img.jpg",item:"كيك مع ايس كريم",price:"4000 IQD",disc:""},
  {id:1604,cat:9,image:"images/items/img.jpg",item:"كيك",price:"3000 IQD",disc:""},
  {id:1605,cat:9,image:"images/items/img.jpg",item:"بقلاوة مع ايس كريم",price:"4000 IQD",disc:""},
  {id:1606,cat:9,image:"images/items/img.jpg",item:"كنافة",price:"5000 IQD",disc:""},
  {id:1607,cat:9,image:"images/items/img.jpg",item:"كاستر",price:"3000 IQD",disc:""},
  {id:1608,cat:9,image:"images/items/img.jpg",item:"مكسرات",price:"5000 IQD",disc:""},
  {id:1609,cat:9,image:"images/items/img.jpg",item:"فوندو",price:"5000 IQD",disc:""},
  {id:1610,cat:9,image:"images/items/img.jpg",item:"كراميل مكياتو",price:"3000 IQD",disc:""},
  {id:1611,cat:9,image:"images/items/img.jpg",item:"وفل كندر",price:"5000 IQD",disc:""},
  {id:1612,cat:9,image:"images/items/img.jpg",item:"وفل نوتيلا",price:"5000 IQD",disc:""},
  {id:1613,cat:9,image:"images/items/img.jpg",item:"وفل لوتس",price:"5000 IQD",disc:""},
  {id:1614,cat:9,image:"images/items/img.jpg",item:"وفل كيت كات",price:"5000 IQD",disc:""},
  {id:1615,cat:9,image:"images/items/img.jpg",item:"كريب",price:"5000 IQD",disc:""},
  {id:1616,cat:9,image:"images/items/img.jpg",item:"بان كيك",price:"5000 IQD",disc:""},
  {id:1617,cat:9,image:"images/items/img.jpg",item:"ماعون فواكه",price:"5000 IQD",disc:""},
  {id:1618,cat:9,image:"images/items/img.jpg",item:"ماعون فواكه دبل",price:"8000 IQD",disc:""},
  {id:1619,cat:9,image:"images/items/img.jpg",item:"حلاوة فستق بالايس كريم",price:"4000 IQD",disc:""},
  

  // Cat: 10   // Cat: 11
  {id:1801,cat:10,image:"images/items/img.jpg",item:"همبركر لحم",price:"5000 IQD",disc:""},
  {id:1802,cat:10,image:"images/items/img.jpg",item:"همبركر دجاج",price:"4000 IQD",disc:""},
  {id:1803,cat:10,image:"images/items/img.jpg",item:"شاورما لحم",price:"4000 IQD",disc:""},
  {id:1804,cat:10,image:"images/items/img.jpg",item:"شاورما دجاج",price:"3500 IQD",disc:""},
  {id:1805,cat:10,image:"images/items/img.jpg",item:"فاهيتا لحم",price:"5000 IQD",disc:""},
  {id:1806,cat:10,image:"images/items/img.jpg",item:"فاهيتا دجاج",price:"4000 IQD",disc:""},
  {id:1807,cat:10,image:"images/items/img.jpg",item:"سمك تونا",price:"3000 IQD",disc:""},
  {id:1808,cat:10,image:"images/items/img.jpg",item:"فنكر",price:"3000 IQD",disc:""},
  {id:1809,cat:10,image:"images/items/img.jpg",item:"سندويج سكالوب مقرمش",price:"4000 IQD",disc:""},
  {id:1810,cat:10,image:"images/items/img.jpg",item:"ستيك دجاج دايت",price:"8000 IQD",disc:""},
  {id:1811,cat:10,image:"images/items/img.jpg",item:"قلي لحم",price:"10000 IQD",disc:""},
  {id:1812,cat:10,image:"images/items/img.jpg",item:"قلي دجاج",price:"8000 IQD",disc:""},
  {id:1813,cat:10,image:"images/items/img.jpg",item:"وجبة فاهيتا لحم",price:"8000 IQD",disc:""},
  {id:1814,cat:10,image:"images/items/img.jpg",item:"وجبة فاهيتا دجاج",price:"7000 IQD",disc:""},
  {id:1815,cat:10,image:"images/items/img.jpg",item:"سكالوب مقرمش",price:"7000 IQD",disc:""},
  {id:1816,cat:10,image:"images/items/img.jpg",item:"كبة مصلاوية",price:"6000 IQD",disc:""},
  {id:1817,cat:10,image:"images/items/img.jpg",item:"بورك وبتيتة جاب",price:"7000 IQD",disc:""},
  {id:1818,cat:10,image:"images/items/img.jpg",item:"كبة حلب",price:"7000 IQD",disc:""},

  // Cat: 12
  {id:2001,cat:11,image:"images/items/img.jpg",item:"بيتزا لحم",price:"8000 IQD",disc:""},
  {id:2002,cat:11,image:"images/items/img.jpg",item:"بيتزا دجاج",price:"7000 IQD",disc:""},
  {id:2003,cat:11,image:"images/items/img.jpg",item:"بيتزا بابيروني",price:"7000 IQD",disc:""},
  {id:2004,cat:11,image:"images/items/img.jpg",item:"بيتزا مشكل",price:"8000 IQD",disc:""},
  {id:2005,cat:11,image:"images/items/img.jpg",item:"بيتزا خضراوات",price:"7000 IQD",disc:""},

  // Cat: 12
  {id:2201,cat:12,image:"images/items/img.jpg",item:"سلطة يونانية",price:"5000 IQD",disc:""},
  {id:2202,cat:12,image:"images/items/img.jpg",item:"سلطة سيزار",price:"5000 IQD",disc:""},
  {id:2203,cat:12,image:"images/items/img.jpg",item:"سلطة فرنسية",price:"5000 IQD",disc:""},
  {id:2204,cat:12,image:"images/items/img.jpg",item:"سلطة فتوش",price:"5000 IQD",disc:""},
  {id:2205,cat:12,image:"images/items/img.jpg",item:"سلطة شرقية",price:"5000 IQD",disc:""},
  {id:2206,cat:12,image:"images/items/img.jpg",item:"سلطة لافاكادو",price:"6000 IQD",disc:""},
  {id:2207,cat:12,image:"images/items/img.jpg",item:"سلطة مانكو",price:"6000 IQD",disc:""},

  {id:2401,cat:13,image:"images/items/img.jpg",item:"علك ونعناع",price:"8000 IQD",disc:""},
  {id:2402,cat:13,image:"images/items/img.jpg",item:"ليمون ونعناع",price:"8000 IQD",disc:""},
  {id:2403,cat:13,image:"images/items/img.jpg",item:"تفاحتين",price:"8000 IQD",disc:""},
  {id:2404,cat:13,image:"images/items/img.jpg",item:"عنب",price:"8000 IQD",disc:""},
  {id:2405,cat:13,image:"images/items/img.jpg",item:"خلطة فرانك",price:"8000 IQD",disc:""},
  {id:2406,cat:13,image:"images/items/img.jpg",item:"بلو بيري",price:"8000 IQD",disc:""},
  {id:2407,cat:13,image:"images/items/img.jpg",item:"بغدادي",price:"8000 IQD",disc:""},
  {id:2408,cat:13,image:"images/items/img.jpg",item:"علك وبطيخ",price:"8000 IQD",disc:""},
  {id:2409,cat:13,image:"images/items/img.jpg",item:"باوندي",price:"8000 IQD",disc:""},
  {id:2410,cat:13,image:"images/items/img.jpg",item:"طبيعي",price:"10000 IQD",disc:""},
  {id:2411,cat:13,image:"images/items/img.jpg",item:"حجر طبيعي سفري",price:"6000 IQD",disc:""},
  {id:2412,cat:13,image:"images/items/img.jpg",item:"حجر اركيلة سفري",price:"5000 IQD",disc:""},
  {id:2413,cat:13,image:"images/items/img.jpg",item:"خلطة روسية",price:"8000 IQD",disc:""},
]

export {menuItemsCategories,menuItems}