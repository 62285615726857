import React from 'react'
import globalData from '../data/global'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHashtag } from '@fortawesome/fontawesome-free-solid';
const SocialMedia = () => {
  return (
    <div>
      <span><a href="https://www.instagram.com/frank.rest.cafe/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faHashtag}/> {globalData.followUsOnInsta}</a></span>
    </div>
  )
}

export default SocialMedia
